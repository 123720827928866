import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Col, Table } from "react-bootstrap";
import swal from "sweetalert";

import ParceiroService from "../../services/ParceiroService";

import Button from "../../components/CustomButton/CustomButton.jsx";
import Select from "../../components/Select";
import Paginator from "../../components/Paginator";
import Card from "../../components/Card/Card.jsx";

import "react-confirm-alert/src/react-confirm-alert.css";

const parceiroService = new ParceiroService();

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}

export default function UserList() {
  const [branchs, setBranchs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [page, setPage] = useState(1);
  const [totalBranchs, setTotalBranchs] = useState(0);

  const [search, setSearch] = useState("");
  const [orderby, setOrderby] = useState("id");
  const [order, setOrder] = useState("DESC");

  const limit = 20;

  async function getBranchs() {
    try {
      const response = await parceiroService.getBranchs(
        {
          limit,
          page,
          order,
          orderby,
          search,
        },
        "no-cache"
      );

      setBranchs(response.data.branch_collections.branch);
    } catch (err) {
      throw err;
    }
  }

  async function getCountBranchs() {
    try {
      const response = await parceiroService.getBranchsCount({
        limit,
        page,
        order,
        orderby,
        search,
      });

      setTotalBranchs(response.data.branch_count.count);
    } catch (err) {
      throw err;
    }
  }

  async function handleSubmit(event) {
    // Só haverá o event se for busca pelo form de filtros
    if (event) {
      event.preventDefault();
      setPage(1);
    }

    try {
      setError("");
      setLoading(true);

      await getBranchs();
      await getCountBranchs();
    } catch (e) {
      setError("Erro :(");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleSubmit();
  }, [page]);

  async function deleteBranch(id) {
    await parceiroService.deleteBranch(id);

    const branchsFilter = branchs.filter((branch) => branch.id !== id);

    setBranchs(branchsFilter);
  }

  async function handleToggleActive(id, currentStatus, name) {
    swal({
      title: `Tem certeza que deseja ${currentStatus ? 'inativar' : 'ativar'} o ramo de atuação "${name}"?`,
      icon: "warning",
      buttons: ["Cancelar", "OK"],
      dangerMode: true,
    })
      .then(async (willToggle) => {
        if (willToggle) {
          try {
            await parceiroService.updateBranch({
              id,
              showSignUpPage: !currentStatus
            });
            swal(`Ramo de atuação "${name}" ${currentStatus ? 'inativado' : 'ativado'}!`, {
              icon: "success",
            });
            await getBranchs();
          } catch (e) {
            setError("Erro ao atualizar o status.");
          }
        }
      });
  }

  function handleDeleteBranch(id, name) {
    swal({
      title: `Atenção!`,
      text: `Tem certeza que deseja excluir o ramo de atuação "${name}"?\n\nNão será possível recuperar o registro após a exclusão`,
      icon: "warning",
      buttons: ["Cancelar", "Excluir"],
      dangerMode: true,
      className: "custom-swal",
    }).then(async (willDelete) => {
      if (willDelete) {
        await deleteBranch(id);
        swal("Exclusão concluída com sucesso!", {
          icon: "success",
        });
      }
    });
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <Link to="/Branch-new" className="btn btn-info btn-fill">
                Novo ramo
              </Link>
            </div>

            <div>
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col md={4}>
                        <input
                          type="text"
                          name="search"
                          className="form-control"
                          placeholder="Pesquisar..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </Col>

                      <Col md={2}>
                        <Button bsStyle="info" fill type="submit">
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6} sm={12}>
                    <Row>
                      <Col md={4}>
                        <Select
                          name="orderby"
                          placeholder="Ordernar por"
                          onChange={({ value }) => setOrderby(value)}
                          options={[
                            {
                              value: "id",
                              label: "ID",
                            },
                            {
                              value: "value",
                              label: "Nome",
                            },
                          ]}
                        />
                      </Col>

                      <Col md={4}>
                        <Select
                          name="order"
                          placeholder="Tipo de ordenação"
                          onChange={({ value }) => setOrder(value)}
                          options={[
                            {
                              value: "DESC",
                              label: "Decrescente",
                            },
                            {
                              value: "ASC",
                              label: "Crescente",
                            },
                          ]}
                        />
                      </Col>
                      <Col md={2}>
                        <Button bsStyle="info" fill type="submit">
                          Ordenar
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            </div>

            <Card
              title="Ramos de Atuação"
              name="Ramos de Atuação"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Título</th>
                      <th>Status</th>
                      <th />

                      <th style={{ textAlign: "right" }}>Ações</th>

                    </tr>
                  </thead>

                  <tbody>
                    {error && (
                      <tr>
                        <td colSpan={11}>{error}</td>
                      </tr>
                    )}

                    {loading ? (
                      <tr>
                        <td colSpan={11}>Carregando...</td>
                      </tr>
                    ) : (

                        branchs.map(item => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.value}</td>
                            <td>{item.showSignUpPage ? 'Ativo' : 'Inativo'}</td>
                            <td style={{ textAlign: 'right' }}>
                              <Link
                                className="btn btn-info"
                                to={`/Branch-edit/${item.id}`}
                              >
                                Editar
                            </Link>
                            <button
                              className="btn btn-warning"
                              onClick={() => handleToggleActive(item.id, item.showSignUpPage, item.value)}
                              style={{ marginLeft: 16 }}
                            >
                              {item.showSignUpPage ? 'Inativar' : 'Ativar'}
                            </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => handleDeleteBranch(item.id, item.value)}
                                style={{ marginLeft: 16 }}
                              >
                                Excluir
                            </button>
                            </td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </Table>
              }
            />
          </Col>

          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalBranchs / limit)}
              onChangePage={(page) => setPage(page)}
              siblingCount={2}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
